import React, { useEffect, useState } from "react";
import BetResultHistory from "./components/BetResultHistory";
import BettingForm from "./components/BettingForm";
import Header from "./components/Header";
import ProgressBar from "./components/ProgressBar";
import Lottie from "lottie-react";
import loading from "./assets/loading.json";
import win from "./assets/win.json";
import useSolana from "./useSolana";
import { useWallet } from "@solana/wallet-adapter-react";
import { ref, query, orderByChild, limitToLast, onValue } from "firebase/database";
import { database } from "./firebase"; // Ensure correct path

function App() {
    const [resultHistory, setResultHistory] = useState([]); // Track bet history
    const [activeValue, setActiveValue] = useState(2);
    const [rollState, setRollState] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const [balance, setBalance] = useState(null);
    const [houseSol, setHouseSol] = useState(null);
    const { publicKey } = useWallet(); // Access the wallet context
    const { connection, houseAccount } = useSolana();

    const [result, setResult] = useState({
        isShow: false,
        randomNumber: "",
        isWin: "",
    });

    useEffect(() => {
        let intervalId;

        const getBalance = async () => {
            if (publicKey) {
                try {
                    // Fetch the SOL balance of the connected wallet
                    const balanceLamports = await connection.getBalance(publicKey);
                    const balanceSol = balanceLamports / 1e9; // Convert lamports to SOL
                    setBalance(balanceSol);

                    const houseLamports = await connection.getBalance(houseAccount);
                    const houseSol = houseLamports / 1e9; // Convert lamports to SOL
                    setHouseSol(houseSol.toFixed(2));
                } catch (err) {
                    console.error("Failed to fetch balance:", err);
                }
            }
        };

        if (publicKey) {
            getBalance(); // Initial call to get the balance right away
            intervalId = setInterval(getBalance, 5000); // Call getBalance every 5 seconds
        }

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, [publicKey, connection, houseAccount]); // Add dependencies for updates

    useEffect(() => {
        async function getLatestGameResults() {
            try {
                // Reference to the 'gameResults' node
                const gameResultsRef = ref(database, "gameResults");

                // Query to get the last 15 entries ordered by the 'timestamp' field
                const latestEntriesQuery = query(gameResultsRef, orderByChild("timestamp"), limitToLast(15));

                // Listen for real-time updates using 'onValue'
                onValue(latestEntriesQuery, (snapshot) => {
                    if (snapshot.exists()) {
                        const data = snapshot.val();

                        // Convert the result from an object to an array for easier handling
                        const entries = Object.entries(data)
                            .map(([key, value]) => ({
                                id: key, // Keep the key for reference
                                ...value, // Spread the rest of the data
                            }))
                            .sort((a, b) => b?.timestamp - a?.timestamp);

                        // Update the result history state
                        setResultHistory(entries);
                        console.log("Latest 15 game results (real-time):", entries);
                    } else {
                        console.log("No data available");
                        setResultHistory([]); // Clear the result history if no data is available
                    }
                });
            } catch (error) {
                console.error("Error fetching latest game results:", error);
            }
        }
        getLatestGameResults();
        return () => {};
    }, []);

    return (
        <div>
            <div className="min-h-screen back-main text-white flex flex-col items-center justify-center  px-8">
                <Header balance={balance} />
                <div
                    className="w-full pt-24 mb-4 relative h-[70vh] bg-cover bg-center rounded-xl"
                    style={{ backgroundImage: `url('./assets/imgs/back.png')` }}
                >
                    {result?.isShow ? (
                        <div className="transition-opacity duration-1000 ease-in-out">
                            <p className="text-5xl w-full text-center">{result?.randomNumber}</p>
                            <p className="text-3xl w-full text-center">{result?.isWin ? "You Won!" : "You Lost"}</p>
                        </div>
                    ) : null}

                    <div className="text-4xl fixed top-28">
                        {houseSol ? <p className="handjet sol-amount">{houseSol + "SOL"}</p> : null}
                    </div>
                    <div className="absolute bottom-[12%] w-5/6 sm:w-3/5 left-1/2 transform -translate-x-1/2 flex justify-center">
                        <ProgressBar activeValue={activeValue} setActiveValue={setActiveValue} rollState={rollState} />
                    </div>
                </div>
                <div className="my-4 rounded-xl back-contain">
                    <BettingForm
                        activeValue={activeValue}
                        rollState={rollState}
                        setRollState={setRollState}
                        setIsLoading={setIsLoading}
                        setResult={setResult}
                    />
                </div>
                <div className="my-4 rounded-xl back-contain">
                    <div className="w-full">
                        <BetResultHistory resultHistory={resultHistory} />
                    </div>
                </div>
            </div>
            {isLoading ? (
                <div className="fixed w-screen h-screen top-0 left-0 backdrop-blur-md flex items-center justify-center bg-[#0000000a]">
                    <div className="w-48">
                        <Lottie animationData={loading} loop={true} />
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default App;
