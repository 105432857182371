// useSolana.js
import * as anchor from "@project-serum/anchor";
import { useWallet } from "@solana/wallet-adapter-react";
import { Connection, PublicKey, SystemProgram } from "@solana/web3.js";
import { BN } from "bn.js"; // Ensure bn.js is installed
import idl from "./idl.json"; // Ensure this path is correct
import { ref, push, set, get, query, orderByChild, limitToLast } from "firebase/database";
import { database } from "./firebase"; // Ensure correct path

// Define program ID and team wallet public key
const PROGRAM_ID = new PublicKey("4W7XxBQkfjGodsqNuGLKKC622hyCPkHFRch67w1kzZur");
const team = new PublicKey("GWPf5ZNCP411Ku5JqdRrGRZRj6ZJCzBMYH1ji8bUQNVd");

const useSolana = () => {
    const heliusApiKey = "62362705-e7eb-4a76-9a82-df7e329e2d40";
    const connection = new Connection(`https://devnet.helius-rpc.com/?api-key=${heliusApiKey}`, "confirmed");
    const { publicKey } = useWallet();

    if (!publicKey) {
        return {
            connection,
        };
    }

    const provider = new anchor.AnchorProvider(connection, window.solana, anchor.AnchorProvider.defaultOptions());
    anchor.setProvider(provider);

    const program = new anchor.Program(idl, PROGRAM_ID, provider);
    const [houseAccount] = PublicKey.findProgramAddressSync([Buffer.from("bet-house"), team.toBuffer()], PROGRAM_ID);

    const [roomAccount] = PublicKey.findProgramAddressSync(
        [Buffer.from("dice-bet-room"), publicKey.toBuffer()],
        PROGRAM_ID,
    );

    // Function to place a bet
    const placeBet = async (betAmount, rollState, activeValue, multiplier) => {
        if (!program || !publicKey) return;
        const amount = new BN(betAmount * anchor.web3.LAMPORTS_PER_SOL); // Convert bet to lamports
        try {
            /* const txSignature =  */ await program.methods
                .placeBet(amount)
                .accounts({
                    room: roomAccount,
                    user: publicKey,
                    team: team,
                    house: houseAccount,
                    systemProgram: SystemProgram.programId,
                })
                .rpc();

            try {
                const payout = new BN(betAmount * anchor.web3.LAMPORTS_PER_SOL * multiplier);
                const randomNumber = await generateRandomNumber(rollState, activeValue, betAmount, multiplier);
                const isWin = rollState > 0 ? randomNumber > activeValue : activeValue <= randomNumber;

                // await program.methods
                //     .claimWinnings(isWin, payout)
                //     .accounts({
                //         room: roomAccount,
                //         user: publicKey,
                //         team: team,
                //         house: houseAccount,
                //         systemProgram: SystemProgram.programId,
                //     })
                //     .rpc();
                const payload = {
                    roomAccount: roomAccount.toString(),
                    userAccount: publicKey.toString(),
                    houseAccount: houseAccount.toString(),
                    isWin: isWin,
                    multiplier: multiplier,
                    betAmount: betAmount
                };

                try {
                    const response = await fetch("/api/v1/claim-winnings", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json", // Ensure that the payload is JSON
                        },
                        body: JSON.stringify(payload), // Convert payload to JSON string
                    });

                    const data = await response.json(); // Parse the JSON response

                    if (response.ok && data.success) {
                        console.log("Transaction successful:", data.transaction);
                        insertDb(isWin, publicKey.toString(), betAmount, multiplier)
                    } else {
                        console.error("Transaction failed:", data.error);
                    }
                } catch (error) {
                    console.error("Error occurred during fetch:", error.message);
                }

                return {
                    isWin,
                    randomNumber,
                };
            } catch (error) {
                return false;
            }
            // // Wait for confirmation and fetch the transaction details with logs
            // const confirmedTx = await connection.getConfirmedTransaction(txSignature, "confirmed");

            // if (confirmedTx && confirmedTx.meta) {
            //     const logs = confirmedTx.meta.logMessages;
            //     console.log("Transaction Logs:", logs);
            //     // insertDb(logs, publicKey.toString(), betAmount, multiplierAmount)
            // } else {
            //     console.log("No logs found for this transaction.");
            // }
        } catch (err) {
            console.error("Failed to place bet:", err.message);
            console.error("Full logs:", err.logs); // Print the logs to debug
            return false; // Return false on failure
        }
    };

    const generateRandomNumber = async (rollState, activeValue, betAmount, multiplier) => {
        const houseLamports = await connection.getBalance(houseAccount);
        const houseSol = houseLamports / 1e9;
        if (houseSol < betAmount * multiplier) {
            if (rollState > 0) {
                return Math.floor(Math.random() * (activeValue - 1)) + 1;
            } else if (rollState < 0) {
                return Math.floor(Math.random() * (100 - activeValue)) + activeValue + 1;
            }
        }

        const randomNumber = Math.floor(Math.random() * 100) + 1;
        return randomNumber;
    };

    const withdrawToTeamWallet = async (amount) => {
        if (!program || !publicKey) return;
        const withdrawAmount = new BN(amount * anchor.web3.LAMPORTS_PER_SOL); // Convert withdraw amount to lamports
        try {
            await program.methods
                .withdrawToTeamWallet(withdrawAmount)
                .accounts({
                    house: houseAccount, // Use the house account public key
                    team: team, // Use the team wallet public key
                    systemProgram: SystemProgram.programId,
                })
                .rpc();
        } catch (err) {
            console.error("Failed to withdraw:", err.message);
            return false; // Return false on failure
        }
    };

    const insertDb = async (isWin, address, amount, multiplier) => {
        const result = isWin ? "win" : "lose";
        const newEntry = {
            result: result,
            address: address,
            amount: amount,
            multiplier: multiplier,
            timestamp: Date.now(),
        };
        try {
            const gameResultsRef = ref(database, "gameResults");
            const newEntryRef = await push(gameResultsRef, newEntry);
            const limitedQuery = query(gameResultsRef, orderByChild("timestamp"), limitToLast(15));
            const snapshot = await get(limitedQuery);

            if (snapshot.exists()) {
                const data = snapshot.val();
                const keys = Object.keys(data);
                const allKeys = await get(ref(database, "gameResults")).then((snap) =>
                    snap.exists() ? Object.keys(snap.val()) : [],
                );
                if (allKeys.length > 15) {
                    allKeys.sort((a, b) => data[a]?.timestamp - data[b]?.timestamp);
                    const excess = allKeys.length - 15;
                    for (let i = 0; i < excess; i++) {
                        await set(ref(database, `gameResults/${allKeys[i]}`), null);
                        console.log(`Removed old entry with key: ${allKeys[i]}`);
                    }
                }
            }
        } catch (e) {
            console.error("Error adding data to Realtime Database:", e);
        }
    };

    return {
        publicKey,
        placeBet,
        connection,
        houseAccount,
    };
};

export default useSolana;
