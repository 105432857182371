import React, { useMemo, useState } from "react";
import Modal from "react-modal";
import useSolana from "../useSolana";

Modal.setAppElement("#root");

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        background: "linear-gradient(19deg, #441f09, #0006)",
        color: "white",
        minWidth: "30%",
    },
    overlay: {
        background: "#00000000",
        backdropFilter: "blur(5px)",
        zIndex: 10,
    },
};

//rollState = 1 ? roll over, rollState = -1 ? roll under
const BettingForm = ({ activeValue = 2, rollState = 1, setRollState, setIsLoading, setResult }) => {
    const [winChance, setWinChance] = useState(null);
    const { placeBet } = useSolana();
    const multiplier = useMemo(() => {
        const value = activeValue * 25;
        const baseMultiplier = rollState > 0 ? (100 - value) / 100 : value / 100;
        setWinChance(baseMultiplier * 100);
        return baseMultiplier !== 0 ? (1 / baseMultiplier).toFixed(2) * (1 - 0.025) : 0.975;
    }, [activeValue, rollState]);

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [betAmount, setBetAmount] = useState(0.1);

    const handleSubmit = async () => {
        console.log("Bet Amount Submitted:", betAmount);
        if (betAmount >= 0.1) {
            if (betAmount && rollState && activeValue && multiplier) {
                setIsOpen(false);
                try {
                    setIsLoading(true);
                    const result = await placeBet(betAmount, rollState, activeValue * 25, multiplier);
                    if (result !== false) {
                        const { isWin, randomNumber } = result;
                        setIsLoading(false);
                        setResult({ isWin: isWin, randomNumber: randomNumber, isShow: true });
                        setTimeout(() => {
                            setResult((prev) => ({
                                ...prev,
                                isShow: false,
                            }));
                        }, 5000);
                    }
                } catch (error) {
                    setIsLoading(false);
                }
            }
        } else {
            closeModal(); // Close the modal after submitting
        }
    };

    const openModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <div className="flex justify-between items-end w-full">
            <div className="flex flex-col space-y-2">
                <span className="text-white text-xl">Multiplier</span>
                <div className="flex items-center space-x-2 back-gradient md:w-[180px] p-2 rounded-lg shadow-inner">
                    <span className="text-white text-xl">{multiplier}</span>
                </div>
            </div>

            <div className="flex flex-col space-y-2">
                <span className="text-white text-xl">Bet Type:</span>
                <div
                    onClick={() => setRollState(rollState > 0 ? -1 : 1)}
                    className="flex items-center space-x-2 back-gradient  md:w-[180px] p-2 rounded-lg shadow-inner cursor-pointer"
                >
                    {rollState > 0 ? "Roll Over" : "Roll Under"}
                </div>
            </div>

            <div className="flex flex-col space-y-2">
                <span className="text-white text-xl">Win Chance</span>
                <div className="flex items-center space-x-2 back-gradient  md:w-[180px] p-2 rounded-lg shadow-inner">
                    <span className="text-white text-xl">{winChance}%</span>
                </div>
            </div>

            <button
                onClick={openModal}
                className="betBtn text-xl font-light text-white md:w-[180px] py-2 px-4 rounded-lg shadow-lg transition duration-300"
            >
                Place a Bet
            </button>

            <Modal
                isOpen={modalIsOpen}
                // onRequestClose={closeModal}
                style={customStyles} // Apply the custom styles
                contentLabel="Bet Modal"
            >
                <button onClick={closeModal} className="text-white hover:text-gray-300 float-right">
                    &times;
                </button>
                <div className="mt-4">
                    <h2 className="text-xl font-bold mb-4">Enter Bet Amount</h2>
                    <form>
                        <input
                            type="number"
                            placeholder="Enter your bet"
                            value={betAmount}
                            onChange={(e) => {
                                if (isNaN(e.target.value) || e.target.value < 0.1) {
                                    setBetAmount(0.1);
                                } else {
                                    setBetAmount(e.target.value);
                                }
                            }}
                            className="w-full p-2 bg-transparent text-white border border-gray-600 rounded-sm focus:outline-none focus:ring-2 focus:ring-yellow-500"
                        />
                    </form>
                    <div className="mt-6 flex justify-end space-x-3">
                        <button
                            onClick={handleSubmit}
                            className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
                        >
                            OK
                        </button>
                        <button
                            onClick={closeModal}
                            className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default BettingForm;
