import React from "react";
import moment from "moment";

function BetResultHistory({ resultHistory }) {
    return (
        <div className="mt-6">
            <h4 className="text-3xl mb-4">Bet History</h4>
            <div className="space-y-2">
                {resultHistory.length === 0 ? (
                    <p>No bets yet.</p>
                ) : (
                    resultHistory.map((entry, index) => (
                        <div key={index} className={`p-3 rounded-lg flex justify-between items-center`}>
                            <div className="flex items-center space-x-2">
                                <p className="font-semibold text-yellow-400">{entry?.address.slice(0, 4) + "..."}</p>
                                <p className={`text-sm`}>
                                    {entry.result === "win" ? "won" : "lost"}
                                </p>
                                <div className={`flex items-center justify-start rounded-lg px-1 ${entry.result === "win" ? "bg-[#4ade8080]" : "bg-[#9ca3af78]"}`}>
                                    <img className="w-6" src="assets/imgs/sol.png" />
                                    <p className="text-white font-semibold text-sm">{entry.amount} SOL</p>
                                </div>
                                {entry.result === "win" && (
                                    <p className="text-gray-400 text-sm">({entry.multiplier.toFixed(2)}x)</p>
                                )}
                            </div>
                            <p className="text-gray-400 text-sm">{moment(entry.timestamp).fromNow()}</p>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default BetResultHistory;
